import { useContext, useEffect } from "react";
import { checkAddRemoveRows, gatherParamsHelper } from "./helpers";
import { generateQuarters, getQuarterAndYearFromDate, getQuarterFromMonthString } from '../../../utils/Helpers';
import { LandingContext, PacingAnalysisContext, StaticsContext, TargetAllocationContext, UnderwritingContext } from "context";
import { getOmniData, postEstimateCommitments, postEstimateCommitmentsCash } from "api";

export const useGatherParams = () => {
    const pacingAnalysisContext = useContext(PacingAnalysisContext);
    const targetAllocationContext = useContext(TargetAllocationContext);
    const underwritingContext = useContext(UnderwritingContext);
    const { entityId, entityType, tableData, reportDate, useForwardNav } = useContext(LandingContext);
    const {
        assetClassShortNames,
        strategyParameters,
        targetAllocationStrategies,
    } = useContext(StaticsContext);
    
    const { sliderValues } = underwritingContext;
    const {
        growthOnLiquidAssets,
        setPacingParametersKeyValue,
        checkForRenamedStrategy,
        findStrategyByName,
        pacingParameters: {
            commitmentSchedule,
            dateType,
            fiscalMonth,
            commitmentScheduleOptimizer,
            startDate,
            startYear,
            endYear,
            fiscalYearPlusOne,
            scenario = 'base',
        },
    } = pacingAnalysisContext;
    const { exposure, strategyAllocation, targetAllocation: { currency, targetExposureType } } = targetAllocationContext;

    const downTurnInitDate = getQuarterAndYearFromDate(reportDate);
    const endQuarters = generateQuarters(downTurnInitDate, 21);

    /**
     * Figure out if start year is +1 due to fiscal year
     * ending earlier in the year and start date
     */
    const calculateFiscalStart = () => {
        if (dateType === 'fiscal') {
            const month = startDate.substring(5, 7);

            const parsedFiscalMonth =
                fiscalMonth === '' ? 'September' : fiscalMonth;
            setPacingParametersKeyValue('fiscalMonth', parsedFiscalMonth);

            const startQuarter = getQuarterFromMonthString(month);
            const fiscalEndQuarter = getQuarterFromMonthString(
                parsedFiscalMonth
            );

            if (startQuarter > fiscalEndQuarter) {
                setPacingParametersKeyValue('fiscalYearPlusOne', true);
            } else {
                setPacingParametersKeyValue('fiscalYearPlusOne', false);
            }
        } else {
            setPacingParametersKeyValue('fiscalYearPlusOne', false);
        }
    };

    const updateCommitmentSchedule = async () => {
        const values = await checkAddRemoveRows(
            targetAllocationStrategies,
            commitmentSchedule,
            commitmentScheduleOptimizer,
            assetClassShortNames,
            exposure,
            strategyAllocation,
            checkForRenamedStrategy,
            findStrategyByName,
            startYear, 
            endYear,
            fiscalYearPlusOne,
            sliderValues,
            scenario,
        );

        const { newSchedule, newOptimizer } = values;
        setPacingParametersKeyValue('commitmentSchedule', newSchedule);
        setPacingParametersKeyValue('commitmentScheduleOptimizer', newOptimizer);
    }

    useEffect(() => {
        updateCommitmentSchedule();
    }, [scenario, targetExposureType, fiscalYearPlusOne, startYear, endYear]);
    useEffect(() => {
        calculateFiscalStart();
    }, [dateType, fiscalMonth, startDate]);

    // prepared date on mount
    useEffect(() => {
        setPacingParametersKeyValue('quarter', downTurnInitDate.split(" ")[0]);
        setPacingParametersKeyValue('startYear', parseInt(downTurnInitDate.split(" ")[1]));

        setPacingParametersKeyValue('dtStartQtr', downTurnInitDate.split(" ")[0]);
        setPacingParametersKeyValue('dtStartYear', parseInt(downTurnInitDate.split(" ")[1]));
        setPacingParametersKeyValue('dtEndQtr', endQuarters[0].split(" ")[0]);
        setPacingParametersKeyValue('dtEndYear', parseInt(endQuarters[0].split(" ")[1]) + 5);
        setPacingParametersKeyValue('dtPercent', .4);
    }, [])
    
    const getParams = () => {
        return gatherParamsHelper(
            pacingAnalysisContext,
            targetAllocationContext,
            targetAllocationStrategies,
            underwritingContext,
            tableData,
            false, // smoothing (it seems to always set to false)
            assetClassShortNames,
            strategyParameters,
            reportDate,
            useForwardNav
        );
    }

    const runEstimate = async () => {
        const params = getParams();
        if (growthOnLiquidAssets) {
            return await postEstimateCommitmentsCash(params);
        } else {
            return await postEstimateCommitments(params);
        }
    }

    const gatherHistoricalParams = async (numHistoricalYears) => {
        const data = await getOmniData('currencies/codesAndSymbols');
        const { code = '' } = data.find(({ symbol }) => {
            return symbol === currency;
        });

        const ids = [];
        tableData.forEach((row) => {
            if (row.investmentId) {
                ids.push(row.investmentId);
            }
        });

        return {
            investmentId: ids,
            portfolioId: entityId === 0 ? [0] : entityId,
            reportDate: reportDate !== '' ? reportDate : '2019-03-31',
            currency: code || '$',
            useFiscal: dateType,
            fiscalEndMonth: fiscalMonth,
            numberofyears: numHistoricalYears,
            entityType: entityType,
        };
    }

    return {
        getParams,
        runEstimate,
        gatherHistoricalParams,
    }
}