// @flow
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { Node } from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { TargetAllocationContext } from './TargetAllocationContext';

type Props = {
    children: Node,
    history: any,
    location: {
        pathname: string,
    },
};

export const LandingContext = createContext<any>({});
export const LandingContextConsumer = LandingContext.Consumer;

const uuidv4 = require('uuid/v4');

const LandingContextProvider = ({ children, history, location }: Props) => {
    const [indexVersion, setIndexVersion] = useState(null);
    const [rawData, setRawData] = useState([]);
    const [reportDate, setReportDate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [entityId, setEntityId] = useState(0);
    const [entityType, setEntityType] = useState('');
    const [portfolioDropdown, setPortfolioDropdown] = useState([]);
    const [appendExistingCommitments, setAppendExistingCommitments] = useState(false);
    const [historicalExportData, setHistoricalExportData] = useState({});
    const [yearlyHistorical, setYearlyHistorical] = useState(false);
    const [existingCommitmentsColumnsDefs, setExistingCommitmentsColumnsDefs] = useState(null)

    const [
        tableDataPinnedBottomRowData,
        setTableDataPinnedBottomRowData,
    ] = useState([
        {
            Investment: 'Total',
            Vintage: '',
            AssetClass: '',
            InvestmentType: '',
            Sector: '',
            SubSector: '',
            Geography: '',
            Commitment: 0,
            Contributions: 0,
            Distributions: 0,
            AdjNAV: 0,
            ForwardNAV: 0,
            LastNavDate: '',
            Unfunded: 0,
            IRR: '',
            TVM: '',
        },
    ]);
    const [useForwardNav, setUseForwardNav] = useState(false);
    const [isOmniImport, setIsOmniImport] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [isSavedPacing, setIsSavedPacing] = useState(false);

    const { targetAllocationValidation } = useContext(TargetAllocationContext);

    /**
     * Object saved in handleSave function in underwritingContext
     */
    const landingContextDataToSave = {
        indexVersion,
        rawData,
        reportDate,
        tableData,
        tableDataPinnedBottomRowData,
        attributes,
        useForwardNav,
        entityType,
    };

    const updateRawData = (tableDataFromResponse) => {
        const rawDataHolder = [];
        tableDataFromResponse.map((row) => {
            const holder = row;

            if (row.Strategy) {
                holder.Sector = holder.Strategy;
                holder.SubSector = holder.SubStrategy;

                delete holder.Strategy;
                delete holder.SubStrategy;
            }

            // Check if row has an Id. If not, assign one.
            // Convert all IDs to string
            if (!Object.prototype.hasOwnProperty.call(row, 'Id')) {
                holder.Id = uuidv4();
            }
            const convertedId = String(holder.Id);
            holder.Id = convertedId;
            rawDataHolder.push({ ...holder });

            return holder;
        });
        setRawData(rawDataHolder);
    };

    const updateReportDate = (newReportDate, modal = null) => {
        setReportDate(newReportDate);
    };

    /**
     * Set Landing Context on LoadFromDb / SavePacing.
     * Must be called alongside all other context updater functions.
     */
    const updateLandingContext = async (data, reportDateToSet) => {
        // setTableData(data.tableData);
        // updateRawData(data.tableData);
        updateReportDate(reportDateToSet);
        
        // Add unique portfolio names to portfolioDropdown.
        const portfolioHolder = [];
        data.tableData.forEach((row) => {
            if (!portfolioHolder.includes(row.Portfolio) && row.Portfolio) {
                portfolioHolder.push(row.Portfolio);
            }
        });
        setPortfolioDropdown(portfolioHolder);

        const { attributes: saveAttributes, useForwardNav: useFwdNav, entityType: entType } = data;

        setUseForwardNav(useFwdNav || useForwardNav);
        setAttributes(saveAttributes);
        setEntityType(entType)
    };

    const displayValidationError = (message) => {
        Swal.fire({
            type: 'error',
            html: message,
        });
    };

    const validateData = (redirectTo) => {
        if (location.pathname.includes('target-allocation')) {
            targetAllocationValidation(redirectTo);
            return false;
        }

        if (reportDate === '----/--/--') {
            displayValidationError('Report Date is required to proceed');
            return false;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const row of tableData) {
            const {
                Investment,
                Vintage,
                AssetClass,
                Geography,
                Commitment,
                AdjNAV,
                Unfunded,
                ClosingDate,
            } = row;
            const foundInRawData = rawData.find((dataRow) => {
                return dataRow.Investment === Investment;
            });
            if (
                (!ClosingDate || ClosingDate === '') &&
                (!Vintage || Vintage === '' || Vintage === '(Blank)')
            ) {
                displayValidationError(
                    'Closing Date is required for all existing commitments'
                );
                return undefined;
            }
            if (
                parseInt(Vintage, 10) < 1970
            ) {
                displayValidationError(
                    'A valid vintage year is required for all existing commitments'
                );
                return false;
            }
            if (AssetClass === '' || AssetClass === '(Blank)') {
                displayValidationError(
                    'Asset Class is required for all existing commitments'
                );
                return false;
            }
            if (Geography === '' || Geography === '(Blank)') {
                displayValidationError(
                    'Geography is required for all existing commitments'
                );
                return false;
            }
            if (Commitment === '' || Commitment <= 0) {
                displayValidationError(
                    'Commitment is required for all existing commitments and must be greater than 0'
                );
                return false;
            }
            if (AdjNAV === '') {
                displayValidationError(
                    'AdjNAV is required for all existing commitments'
                );
                return false;
            }
            if (Unfunded === '' || Unfunded < 0) {
                displayValidationError(
                    'Unfunded is required for all existing commitments and must be non-negative'
                );
                return false;
            }
            if (
                typeof foundInRawData === 'undefined' &&
                (AdjNAV === '' || AdjNAV <= 0) &&
                (Unfunded === '' || Unfunded <= 0)
            ) {
                displayValidationError(
                    'AdjNAV and Unfunded in a manually added fund cannot both be 0. One must be greater than 0'
                );
                return false;
            }
        }

        if (redirectTo === 'pacing') {
            return true;
        }
        history.push(redirectTo);
        return undefined;
    };

    // get age of fund
    const getFundAge = (closingDate) => {
        // calculate age
        const dateReportDate = new Date(reportDate)
        const dateClosingDate = new Date(closingDate);
        // FIXME: this might not be an absolute
        const diffTime = Math.abs(dateReportDate - dateClosingDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffYears = Math.floor(diffDays/365.25) + 1;
        return Math.min(diffYears, 20);
    }

    // update tableData handle function
    const updateTableData = (table) => {
        if (table) {
            const newTable = table.map((row) => {
                const age = getFundAge(row.ClosingDate);
                return ({
                    ...row,
                    age,
                });
            })
            setTableData(newTable);
        }
    }

    return (
        <LandingContext.Provider
            value={{
                appendExistingCommitments,
                attributes,
                entityId,
                entityType,
                historicalExportData,
                indexVersion,
                isOmniImport,
                isSavedPacing,
                landingContextDataToSave,
                portfolioDropdown,
                rawData,
                reportDate,
                setAppendExistingCommitments,
                setAttributes,
                setEntityId,
                setEntityType,
                setHistoricalExportData,
                setIndexVersion,
                setIsOmniImport,
                setIsSavedPacing,
                setRawData,
                setReportDate,
                setTableData: updateTableData,
                setTableDataPinnedBottomRowData,
                setPortfolioDropdown,
                setUseForwardNav,
                setYearlyHistorical,    
                tableData,
                tableDataPinnedBottomRowData,
                updateLandingContext,
                updateReportDate,
                useForwardNav,
                validateData,
                yearlyHistorical,
                existingCommitmentsColumnsDefs,
                setExistingCommitmentsColumnsDefs,
            }}
        >
            {children}
        </LandingContext.Provider>
    );
};

export default withRouter(LandingContextProvider);
