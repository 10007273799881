// @flow
import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Col, Row, Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import {
    LandingContext,
    PacingAnalysisContext,
    StaticsContext,
    TargetAllocationContext,
} from 'context';
import {
    BottomNav,
    Switch as YearToggle,
    HistoricalInputs,
    HighLowCasesCheckbox,
    SwitchButton
} from 'utils';
import {
    CommitmentNavGraph,
    CommitmentSensitivityGraphParent,
    CommitScheduleGraph,
    GrowthSensitivityGraphParent,
    NavByStratGraph,
    NetCashFlowGraph,
    PacingPanelTabs,
    PacingParameters,
    ProjectedPerformance,
    QuarterlyNavPlot,
    RealEstateExposureGraph,
    DownTurn,
} from './components';
import { getQuarterAndYearFromDate, generateQuarters } from '../utils/Helpers';
import { checkPacingStrategies } from '../Landing/helpers';

type Props = {
    location: { pathname: string },
};

const PacingAnalysis = ({ location: { pathname = '' } }: Props) => {
    const { tableData, reportDate } = useContext(LandingContext);
    const { historicalSetBackValue, setHistoricalSetBackValue } = useContext(PacingAnalysisContext)
    const { targetAllocationStrategies } = useContext(StaticsContext);
    const {
        targetAllocation: { currency },
    } = useContext(TargetAllocationContext);
    const {
        graphData,
        growthOnLiquidAssets: globalGrowthonLiquidAssets,
        setGrowthOnLiquidAssets: setGlobalGrowthOnLiquidAssetsContext,
        pacingParameters: { dateType, fiscalMonth },
        setHistoricalData,
        setPacingParametersKeyValue,
    } = useContext(PacingAnalysisContext);

    const [viewYear, setViewYear] = useState(false);
    const [viewHighLow, setHigLow] = useState(false);
    const [viewHistorical, setViewHistorical] = useState(false);
    const [numHistoricalYears, setNumHistoricalYears] = useState(1);
    const [useCovidScenario, setUseCovidScenario] = useState(false);
    const [loading, setLoading] = useState(true);
    const [growthOnLiquidAssets, setGrowthOnLiquidAssets] = useState(
        globalGrowthonLiquidAssets
    );

    const downTurnURL = /^\/pacing-analysis\/downturn-scenario(\/[\w.-]*)*\/?$/
    const downTurnInitDate = getQuarterAndYearFromDate(reportDate);
    const endQuarters = generateQuarters(downTurnInitDate, 3);

    const [reRunAnalysis, setReRunAnalysis] = useState(false);

    useEffect(() => {
        setPacingParametersKeyValue('dtStartQtr', downTurnInitDate.split(" ")[0]);
        setPacingParametersKeyValue('dtStartYear', parseInt(downTurnInitDate.split(" ")[1]));
        setPacingParametersKeyValue('dtEndQtr', endQuarters[0].split(" ")[0]);
        setPacingParametersKeyValue('dtEndYear', parseInt(endQuarters[0].split(" ")[1]));
        setPacingParametersKeyValue('dtPercent', .4);
    }, [])
    
    useEffect(() => {
        const listOfFunds = checkPacingStrategies(
            tableData,
            targetAllocationStrategies
        );

        if (listOfFunds !== '') {
            Swal.fire({
                type: 'warning',
                html: `<b>Pacing strategy not found for:</b> ${listOfFunds}<b>Please select a different strategy in the Underwriting tab.</b>`,
                width: '600px',
            });
        }

        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, []);

    useEffect(() => {
            setViewYear(historicalSetBackValue.type)
            setNumHistoricalYears(historicalSetBackValue.value)
            setViewHistorical(historicalSetBackValue.isHistoric)
        if (historicalSetBackValue.drawChart) {
            setHistoricalSetBackValue({
                ...historicalSetBackValue,
                drawChart: !historicalSetBackValue.drawChart
            })
        }
    }, [historicalSetBackValue])

    const handleToggle = () => {
        const vwYr = viewYear;
        setViewYear(!viewYear);
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            type: !vwYr
        })
    };

    const handleHighLowToggle = () => {
        setHigLow(!viewHighLow);
    };

    const handleHistoricalToggle = () => {
        const vwHist = viewHistorical
        setViewHistorical(!viewHistorical);
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            isHistoric: !vwHist
        })
    };

    const handleCovidToggle = () => {
        setUseCovidScenario(!useCovidScenario);
    };

    const handleHistoricalYearChange = ({ target: { value } }) => {
        setNumHistoricalYears(value);
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            value: value
        })
    };

    const setGrowthOn = (isGrowthOnLiquidAssets) => {
        setGrowthOnLiquidAssets(isGrowthOnLiquidAssets);
        setGlobalGrowthOnLiquidAssetsContext(isGrowthOnLiquidAssets);
    };

    return (
        <Container fluid>
            {loading ? (
                <Row style={{ height: '-webkit-fill-available' }}>
                    <Col
                        md={12}
                        style={{
                            height: '300px',
                            paddingTop: '25px',
                            textAlign: 'center',
                        }}
                    >
                        <div className='App-background-logo' />
                        <div className='App-loading-text'>Loading...</div>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row>
                        <Col md={12}>
                            <PacingParameters
                                setGrowthOn={setGrowthOn}
                                viewHistorical={viewHistorical}
                                numHistoricalYears={numHistoricalYears}
                                runAnalysis={reRunAnalysis}
                                setRunAnalysis={setReRunAnalysis}
                            />
                        </Col>
                    </Row>
                    <div className='panel' style={{border: '1px solid rgb(189,191,191)', borderRadius: '.25rem'}}>
                        <Row>
                            <Col md={12}>
                                <PacingPanelTabs 
                                    runAnalysis={setReRunAnalysis}
                                    graphData={graphData}
                                />
                                {/* Real Estate does not have quarter data so have to hide toggle */}
                                { ( (pathname !== '/pacing-analysis/real-estate-exposure' && pathname !== '/pacing-analysis/portfolio-stats') && 
                                    !downTurnURL.test(pathname)) && (
                                    <SwitchButton
                                        isOn={viewYear}
                                        handleToggle={handleToggle}
                                        leftLabel='Quarterly'
                                        rightLabel='Yearly'
                                    />
                                )}
                                <Switch>
                                    <Route
                                        path='/pacing-analysis/quarterly-nav'
                                        render={() => (
                                            <QuarterlyNavPlot
                                                growthOnLiquidAssets={
                                                    growthOnLiquidAssets
                                                }
                                                viewYear={viewYear}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/commitments-and-nav'
                                        render={() => (
                                            <>
                                                <HistoricalInputs
                                                    viewHistorical={
                                                        (viewHistorical || historicalSetBackValue.isHistoric)
                                                    }
                                                    handleHistoricalToggle={
                                                        handleHistoricalToggle
                                                    }
                                                    handleHistoricalYearChange={
                                                        handleHistoricalYearChange
                                                    }
                                                    numHistoricalYears={
                                                        historicalSetBackValue.isHistoric ? historicalSetBackValue.value : numHistoricalYears
                                                    }
                                                />
                                                <HighLowCasesCheckbox
                                                    viewHighLow={viewHighLow}
                                                    handleHighLowToggle={
                                                        handleHighLowToggle
                                                    }
                                                />
                                                <CommitmentNavGraph
                                                    viewYear={viewYear}
                                                    viewHistorical={
                                                        viewHistorical
                                                    }
                                                    viewHighLow={viewHighLow}
                                                />
                                            </>
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/net-cash-flows'
                                        render={() => (
                                            <div>
                                                <HistoricalInputs
                                                    viewHistorical={
                                                        (viewHistorical || historicalSetBackValue.isHistoric)
                                                    }
                                                    handleHistoricalToggle={
                                                        handleHistoricalToggle
                                                    }
                                                    handleHistoricalYearChange={
                                                        handleHistoricalYearChange
                                                    }
                                                    numHistoricalYears={
                                                        historicalSetBackValue.isHistoric ? historicalSetBackValue.value : numHistoricalYears
                                                    }
                                                />
                                                <HighLowCasesCheckbox
                                                    viewHighLow={viewHighLow}
                                                    handleHighLowToggle={
                                                        handleHighLowToggle
                                                    }
                                                />
                                                <NetCashFlowGraph
                                                    viewYear={viewYear}
                                                    viewHistorical={
                                                        viewHistorical
                                                    }
                                                    viewHighLow={viewHighLow}
                                                    useCovidScenario={useCovidScenario}
                                                />

                                            </div>
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/downturn-scenario'
                                        render={() =>
                                            <DownTurn 
                                                reportDate={reportDate}
                                                runAnalysis={setReRunAnalysis}
                                                viewHistorical={viewHistorical}
                                                setViewHistorical={setViewHistorical}
                                                handleHistoricalYearChange={setNumHistoricalYears}
                                                numHistoricalYears={numHistoricalYears}
                                            />}
                                    />
                                    <Route
                                        path='/pacing-analysis/commitment-sensitivity'
                                        render={() => (
                                            <CommitmentSensitivityGraphParent
                                                viewYear={viewYear}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/growth-sensitivity'
                                        render={() => (
                                            <GrowthSensitivityGraphParent
                                                viewYear={viewYear}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/portfolio-stats'
                                        render={() => (
                                            graphData.port_returns !== null &&
                                            graphData.port_returns !== undefined &&
                                                <ProjectedPerformance
                                                    portReturns={graphData.port_returns}
                                                    portfolioAge={graphData.portfolio_age}
                                                />
                                        )
                                        }
                                    />
                                    <Route
                                        path='/pacing-analysis/nav-by-strat'
                                        render={() => (
                                            <NavByStratGraph
                                                viewYear={viewYear}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/commit-schedule'
                                        render={() => (
                                            <CommitScheduleGraph
                                                viewYear={viewYear}
                                            />
                                        )}
                                    />
                                    <Route
                                        path='/pacing-analysis/real-estate-exposure'
                                        component={RealEstateExposureGraph}
                                    />
                                </Switch>
                            </Col>
                        </Row>
                        <Row>
                            <BottomNav backTarget='/scenarios' />
                        </Row>
                    </div>
                </>
            )}
        </Container>
    );
};

export default PacingAnalysis;
