import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, TableVariant, useFormContext } from "@ssgglobal/techintnue";
import { UnderwritingContext } from "context";

const RowStrategy = ({ value, label, data, handleAction }) => {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <span>{ value }</span>
            <Button
                sx={{
                    padding: 0,
                    height: '100%',
                    textTransform: 'capitalize',
                }}
                onClick={() => handleAction(data)}
            >
                { label }
            </Button>
        </Grid>
    )
}

const StrategiesTables = () => {
    const {
        getAutoAdjustableStrategies,
    } = useContext(UnderwritingContext);
    // TODO: change scenario to the one selected
    const existingStrategies = getAutoAdjustableStrategies();

    const { watch, setValue } = useFormContext()
    const [available, setAvailable] = useState(existingStrategies)
    const selected = watch('selectedStrategies')

    useEffect(() => {
        // remove from available if already selected
        const selectedNames = selected.map(({ strategy }) => strategy);
        const filterAvailables = available.filter(({ strategy }) => !selectedNames.includes(strategy));
        setAvailable(filterAvailables);
    }, [])

    const handleAddStrategy = (selectedStrategy) => {
        // remove it from available
        const newAvailable = available.filter(({ strategy }) => strategy !== selectedStrategy.strategy)
        setAvailable(newAvailable)
        // add it to selected
        const newSelected = [...selected, selectedStrategy]
        setValue('selectedStrategies', newSelected)
    }
    const handleRemoveStrategy = (selectedStrategy) => {
        // remove it from selected
        const newSelected = selected.filter(({ strategy }) => strategy !== selectedStrategy.strategy)
        setValue('selectedStrategies', newSelected)
        // add it to available
        const newAvailable = [...available, selectedStrategy]
        setAvailable(newAvailable)
    }

    return (
        <Grid
            container
            direction="column"
            gap="25px"
            sx={{
                height: '300px',
                '& .ag-body-viewport': {
                    border: 'var(--ag-borders) var(--ag-border-color)',
                }
            }}
        >
            <Grid item xs={6}>
                <TableVariant
                    gridStyle={{ height: '300px', width: '100%' }}
                    tableType="aggrid"
                    minHeight="300px"
                    agTheme="ag-theme-balham"
                    className="ag-grid-custom"
                    agGridOptions={{
                        height: '300px',
                        rowHeight: 28,
                        columnDefs: [{
                            field: 'strategy',
                            headerName: 'Available Strategies',
                            flex: '1',
                            cellRenderer: RowStrategy,
                            cellRendererParams: {
                                label: 'add',
                                handleAction: handleAddStrategy
                            }
                        }],
                        rowData: available,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TableVariant
                    gridStyle={{ height: '300px', width: '100%' }}
                    tableType="aggrid"
                    minHeight="300px"
                    agTheme="ag-theme-balham"
                    className="ag-grid-custom"
                    agGridOptions={{
                        height: '300px',
                        rowHeight: 28,
                        columnDefs: [{
                            field: 'strategy',
                            headerName: 'Selected Strategies',
                            flex: '1',
                            cellRenderer: RowStrategy,
                            cellRendererParams: {
                                label: 'remove',
                                handleAction: handleRemoveStrategy
                            }
                        }],
                        rowData: selected,
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default StrategiesTables
