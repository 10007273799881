import { Modal } from "@ssgglobal/techintnue";
import React from "react";

const DocumentationModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            title="Auto Adjust Documentation"
            isThemed
            size="lg"
            open={isOpen}
            onClose={onClose}
        >
            <p>
                StepStone analyzed go-forward performance for funds aged six years and later to determine how assumptions might shift for funds as they mature. The Auto Adjust feature leverages those insights by automatically adjusting Fund Life, IRR, and Distribution Delay (bow factor) for older funds to better align with expectations from StepStone’s underlying dataset. The Auto Adjust feature is designed to reduce manual underwriting adjustments from the user by automatically making data-informed changes to the model inputs. 
            </p>
            <p>
                Auto Adjust assumptions are not available across all pacing strategies and the Available Strategies are automatically filtered to the strategies used in the existing pacing. To use the Auto Adjust feature, move strategies to the Selected Strategies table and hit Apply Changes. By default, the feature will not override any custom changes made by the user (bolded values in the table); however, the user may choose to do so by clicking the “Override Existing Edits” box in the modal. 
            </p>
        </Modal>
    )
}

export default DocumentationModal;
