import React from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonBase } from '@ssgglobal/techintnue';
import { Grid } from '@material-ui/core';

const PanelTabs = ({runAnalysis, graphData}) => (
    <div>
        <ul className='nav nav-tabs'>
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/quarterly-nav'
                    >
                        Asset Class Targets
                    </NavLink>
                </div>
            </li>
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/commitments-and-nav'
                    >
                        Commitment and NAV
                    </NavLink>
                </div>
            </li>
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/net-cash-flows'
                    >
                        Cash Flow Analysis
                    </NavLink>
                </div>
            </li>
            {/* <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/downturn-scenario'
                    >
                        Downturn Scenario
                    </NavLink>
                </div>
            </li> */}
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/commitment-sensitivity'
                    >
                        Commitment Sensitivity
                    </NavLink>
                </div>
            </li>
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/growth-sensitivity'
                    >
                        AuM Sensitivity
                    </NavLink>
                </div>
            </li>
            {
                graphData.port_returns !== null &&
                graphData.port_returns !== undefined &&
                <li className='nav-item'>
                    <div className='nav-link'>
                        <NavLink
                            activeClassName='panel-tab-active-2'
                            className='panel-tabs-2'
                            to='/pacing-analysis/portfolio-stats'
                        >
                            Portfolio Stats
                        </NavLink>
                    </div>
                </li>
            }
            <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active-2'
                        className='panel-tabs-2'
                        to='/pacing-analysis/nav-by-strat'
                    >
                        Nav by Strategy
                    </NavLink>
                </div>
            </li>
            <li className='nav-item-last'>
                <ButtonBase
                    style={{
                        minWidth: '120px',
                        backgroundColor: '#007899',
                        color: '#fff',
                        border: '1px solid #007899',
                        borderRadius: '5px',
                        height: '30px',
                    }}
                    onClick={() => runAnalysis(true)}>
                    Run Analysis
                </ButtonBase>
            </li>
            {/* <li className='nav-item'>
                <div className='nav-link'>
                    <NavLink
                        activeClassName='panel-tab-active'
                        className='panel-tabs'
                        to='/pacing-analysis/real-estate-exposure'
                        style={{
                            backgroundColor: colorCombos[7].primary,
                        }}
                    >
                        Real Estate Exposure
                    </NavLink>
                </div>
            </li> */}
        </ul>
    </div>
);

export default PanelTabs;
