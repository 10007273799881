import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Tabs, useFormContext } from '@ssgglobal/techintnue';
import { HistoricalInputs, SwitchButton } from 'utils';
import { CashFlowDownTurnGraph, NavDownTurnGraph } from 'PacingAnalysis/components';


const CashFlowGraph = ({
    viewYear,
    viewHistorical,
    handleToggle,
    historicalYears,
    handleHistoricalToggle,
    handleHistoricalYearChange,
}) => {
    return (
        <Box>
            <SwitchButton 
                isOn={viewYear}
                handleToggle={handleToggle}
                leftLabel='Quarterly'
                rightLabel='Yearly'
            />
            <HistoricalInputs
                viewHistorical={viewHistorical}
                handleHistoricalToggle={handleHistoricalToggle}
                handleHistoricalYearChange={handleHistoricalYearChange}
                numHistoricalYears={historicalYears}
            />
            <CashFlowDownTurnGraph 
                viewYear={viewYear}
                viewHistorical={viewHistorical}
                useCovidScenario={false}
            />
        </Box>
    );
}

const NavGraph = ({
    viewYear,
    viewHistorical,
    handleToggle,
    historicalYears,
    handleHistoricalToggle,
    handleHistoricalYearChange,
}) => {
    return (
        <Box>
            <SwitchButton 
                isOn={viewYear}
                handleToggle={handleToggle}
                leftLabel='Quarterly'
                rightLabel='Yearly'
            />
            <HistoricalInputs
                viewHistorical={viewHistorical}
                handleHistoricalToggle={handleHistoricalToggle}
                handleHistoricalYearChange={handleHistoricalYearChange}
                numHistoricalYears={historicalYears}
            />
            <NavDownTurnGraph
                viewYear={viewYear}
                viewHistorical={viewHistorical}
                isScenario
            />
        </Box>
    );
};

const tabOptions = ['cash', 'nav'];

const GraphSection = ({ runAnalysis }) => {
    const { handleSubmit } = useFormContext();

    const [tabIndex, setTabIndex] = useState('cash')
    const [viewYear, setViewYear] = useState(true);
    const handleToggle = () => setViewYear(!viewYear);

    const [viewHistorical, setViewHistorical] = useState(false);
    const handleHistoricalToggle = () => setViewHistorical(!viewHistorical);

    const [historicalYears, setHistoricalYears] = useState(1);
    const handleHistoricalYearChange = ({ target: {value} }) => {
        setHistoricalYears(value);
    };

    // ensure tab selection is valid
    useEffect(() => {
        // if isn't valid set to default
        if (!tabOptions[tabIndex]) setTabIndex(0);
        // else do nothing
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container>
                <Grid
                    item
                    xs={true}
                    sx={{
                        '& > .MuiBox-root > div[role=tabpanel]': {
                            height: '0px',
                        }
                    }}
                >
                    <Tabs
                        variant="isThemed"
                        initialValue={tabIndex}
                        handleInitialChange={(_event, value) => {
                            setTabIndex(value);
                        }}
                        tabObject={[
                            { tabLabel: 'Cash Flows', },
                            { tabLabel: 'NAV Plot', },
                        ]}
                    />
                </Grid>
                <Grid item sx={{ borderBottom: '1px solid #DEDEE0' }}>
                    <Button theme="primary" onClick={handleSubmit(runAnalysis)}>Run Analysis</Button>
                </Grid>
            </Grid>
            { tabOptions[tabIndex] === 'cash' && (
                <CashFlowGraph
                    viewYear={viewYear}
                    viewHistorical={viewHistorical}
                    handleToggle={handleToggle}
                    historicalYears={historicalYears}
                    handleHistoricalToggle={handleHistoricalToggle}
                    handleHistoricalYearChange={handleHistoricalYearChange}
                />
            ) }
            { tabOptions[tabIndex] === 'nav' && (
                <NavGraph
                    viewYear={viewYear}
                    viewHistorical={viewHistorical}
                    handleToggle={handleToggle}
                    historicalYears={historicalYears}
                    handleHistoricalToggle={handleHistoricalToggle}
                    handleHistoricalYearChange={handleHistoricalYearChange}
                />
            ) }
        </Box>
    );
}

export default GraphSection;
