// @flow
import React, { useContext, useState } from 'react';
import { AuthContext, LandingContext, PacingContext } from 'context';
import { 
    Button,
    Col,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { ButtonDropdown, Grid } from '@ssgglobal/techintnue';
import styled from 'styled-components';
import { SavePacingData } from './components';

type Props = {
    handleSave: Function,
    omitPacingName: boolean,
    text: string | null,
    title: string,
    render?: () => void,
    isPacing?: boolean
};

const SubHeaderWrapper = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
`

const SubHeaderItem = styled.h2`
    font-size: 24px;
    line-heigth: 32px;
    margin: 0px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
`

const PageTitle = ({ handleSave, omitPacingName, text, title, render, isPacing=false }: Props) => {
    const { userSpiClientId, isTrialUser } = useContext(AuthContext);
    const { stepstoneCreated } = useContext(LandingContext); // does not currently exist in context
    const {
        pacingClientReadOnlyAndSpiClientId,
        pacingId,
        pacingName,
    } = useContext(PacingContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const [savePacingIsOpen, setSavePacingIsOpen] = useState(false);

    const checkSave = async () => {
        if (pacingId === 0 || (userSpiClientId && stepstoneCreated)) {
            setSavePacingIsOpen(true);
        } else {
            await handleSave();
        }
    };

    const toggle = () => setIsDropdownOpen((prevState) => !prevState);

    const notSaveTitles = (t) => {
        return ['Pacing Management', 'Management Users'].includes(t);
    }
 
    // TODO: Figure out on a user level which user's have write permissions
    const hideSaveButtons =
        notSaveTitles(title) || pacingClientReadOnlyAndSpiClientId() || isTrialUser;

    const getFullHeader = () => {
        if (pacingName !== '' && !omitPacingName) {
            return `${title} - ${pacingName}`
        }
        return title
    }

    const dropDownStyle = {
        display: 'flex',
        height: '38px',
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    }

    return (
        <Grid container style={{alignItems: 'center', marginBottom: '15px', zIndex:'1',  height: '40px' }}>
            <Grid item md={hideSaveButtons ? 12 : 8}>
                <SubHeaderWrapper>
                    <SubHeaderItem>
                        {getFullHeader()}
                    </SubHeaderItem>
                    {text ? <SubHeaderItem>{text}</SubHeaderItem> : ''}
                </SubHeaderWrapper>
            </Grid>
            {hideSaveButtons ? (
                <></>
            ) : (
                <>
                    <Grid
                        container
                        md={4}
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'end',
                            gap: '16px',
                        }}
                    >
                        {isPacing && (
                            <Grid item>
                                {render()}
                            </Grid>
                            )}
                        <Grid item>
                            {/* <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
                                <DropdownToggle caret style={{background:'#007899', ...dropDownStyle}}>Save</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={checkSave}>Save</DropdownItem>
                                    <DropdownItem onClick={() => setSavePacingIsOpen(true)}>Save As</DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}
                            <ButtonDropdown
                                label="Save"
                                theme="primary"
                                options={[
                                    {
                                        label: 'Save',
                                        onClick: checkSave
                                    },
                                    {
                                        label: 'Save as',
                                        onClick: () => setSavePacingIsOpen(true)
                                    }
                                ]}
                            >
                            </ButtonDropdown>
                        </Grid>
                    </Grid>
                    <SavePacingData
                        open={savePacingIsOpen}
                        toggleModal={() =>
                            setSavePacingIsOpen(!savePacingIsOpen)
                        }
                    />
                </>
            )}
        </Grid>
    );
};

export default PageTitle;
