import React, {useState, useContext, useEffect} from 'react'

import {
    LandingContext,
    PacingAnalysisContext,
    TargetAllocationContext,
} from 'context';
import { numberFormat, getDisplayDates } from 'utils';


const useGraphData = (viewYear, viewHistorical, useCovidScenario) => {
    const {
        graphData,
        graphData: {
            assetclass_yr: assetClassYear,
            assetclass_qtr: assetClassQtr,
            fund_yr_crisis: fundYearCrisis,
            fund_qtr_crisis: fundQtrCrisis,
            fund_yr_hl: fundYearHighLow,
            fund_qtr_hl: fundQtrHighLow,
        },
        historicalData,
        pacingParameters: { dateType, fiscalMonth },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);
    const {
        targetAllocation: { currency },
    } = useContext(TargetAllocationContext);

    const [dataPoints, setDataPoints] = useState({})

    let years = [];
    
    const setData = () => {
        let contributions = [];
        let distributions = [];
        let netCashFlow = [];
        let historicalContrib = [];
        let historicalDistrib = [];
        let historicalNav = [];
        let covidNetCashFlow = [];
        let nav = [];
    
        let covidContributions = [];
        let covidDistributions = [];
        let netCashFlowUncertainty = [];
        let covidNav = [];
        let assetClassData = {};
        let covidData = {};
        let highLowData = {};

        if (viewYear) {
            assetClassData = assetClassYear;
            highLowData = fundYearHighLow;
            covidData = fundYearCrisis;
        } else {
            assetClassData = assetClassQtr;
            highLowData = fundQtrHighLow;
            covidData = fundQtrCrisis;
        }

        if (!assetClassData || !covidData) {
            return;
        }

        const contribHolder = {};
        const distribHolder = {};
        const navHolder = {};
        Object.keys(assetClassData).forEach((key) => {
            const values = assetClassData[key].slice(2);
            if (assetClassData[key][1] === 'Contributions') {
                values.forEach((value, index) => {
                    if (contribHolder[index]) {
                        contribHolder[index] += value * -1;
                    } else {
                        contribHolder[index] = value * -1;
                    }
                });
            } else if (assetClassData[key][1] === 'Distributions') {
                values.forEach((value, index) => {
                    if (distribHolder[index]) {
                        distribHolder[index] += value;
                    } else {
                        distribHolder[index] = value;
                    }
                });
            } else if (assetClassData[key][1] === 'NAV') {
                values.forEach((value, index) => {
                    if (navHolder[index]) {
                        navHolder[index] += value;
                    } else {
                        navHolder[index] = value;
                    }
                });
            }
        });

        
        const emptyFutureContrib = [];
        const emptyFutureDistrib = [];
        const emptyFutureNetCashFlows = [];
        const emptyFutureNav = [];
        Object.keys(contribHolder).forEach((key) => {
            contributions.push(numberFormat(contribHolder[key], 1));
            distributions.push(numberFormat(distribHolder[key], 1));
            netCashFlow.push(
                numberFormat(distribHolder[key] + contribHolder[key], 1)
            );

            emptyFutureContrib.push();
            emptyFutureDistrib.push();
            emptyFutureNetCashFlows.push();
            emptyFutureNav.push()
        });

        const covidContribHolder = {};
        const covidDistribHolder = {};
        const covidNavHolder = {};
        Object.keys(covidData).forEach((key) => {
            const cValues = covidData[key].slice(4);
            if (covidData[key][3] === 'Contributions.Crisis') {
                cValues.forEach((value, index) => {
                    if (covidContribHolder[index]) {
                        covidContribHolder[index] += value * -1;
                    } else {
                        covidContribHolder[index] = value * -1;
                    }
                });
            } else if (covidData[key][3] === 'Distributions.Crisis') {
                cValues.forEach((value, index) => {
                    if (covidDistribHolder[index]) {
                        covidDistribHolder[index] += value;
                    } else {
                        covidDistribHolder[index] = value;
                    }
                });
            } else if (covidData[key][3] === 'NAV.Crisis') {
                cValues.forEach((value, index) => {
                    if (covidNavHolder[index]) {
                        covidNavHolder[index] += value * 1;
                    } else {
                        covidNavHolder[index] = value * 1;
                    }
                });
            }

        });

        Object.keys(covidContribHolder).forEach((key) => {
            covidContributions.push(numberFormat(covidContribHolder[key], 1));
            covidDistributions.push(numberFormat(covidDistribHolder[key], 1));
            covidNetCashFlow.push(
                numberFormat(
                    covidDistribHolder[key] + covidContribHolder[key],
                    1
                )
            );
            covidNav.push(numberFormat(covidNavHolder[key], 1))
        });

        Object.keys(navHolder).forEach((key) => {
            nav.push(numberFormat(navHolder[key], 1));
        });

        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                    }
                }
            }
        } else {
            ({ years } = getDisplayDates(
                graphData.qtrdates,
                null,
                useForwardNav
            ));
        }

        if (historicalData && viewHistorical) {
            const {
                historical_annual: {
                    Year,
                    contrib: annualContribution,
                    distrib: annualDistribution,
                    ncf: annualNcf,
                    nav: annualNav
                },
                historical_qtr: {
                    date,
                    contrib: qtrContribution,
                    distrib: qtrDistribution,
                    ncf: qtrNcf,
                    nav: qrtNav
                },
            } = historicalData;

            // Format all values to 2 decimals
            const annualContrib = annualContribution.map((value) => {
                return numberFormat(value * -1, 1);
            });
            const qtrContrib = qtrContribution.map((value) => {
                return numberFormat(value * -1, 1);
            });
            const annualDistrib = annualDistribution.map((value) => {
                return numberFormat(value, 1);
            });
            const qtrDistrib = qtrDistribution.map((value) => {
                return numberFormat(value, 1);
            });
            const annualNetCashFlow = annualNcf.map((value) => {
                return numberFormat(value, 1);
            });
            const qtrNetCashFlow = qtrNcf.map((value) => {
                return numberFormat(value, 1);
            });
            const annualNv = annualNav.map((value) => {
                return numberFormat(value, 1);
            });
            const qtrNv = qrtNav.map((value) => {
                return numberFormat(value, 1);
            })

            const latestYear = Year[Year.length - 1].toString();
            const { years: histDates } = getDisplayDates(date);

            const newContrib = viewYear ? [...annualContrib] : [...qtrContrib];
            const newDistrib = viewYear ? [...annualDistrib] : [...qtrDistrib];
            const newNav = viewYear ? [...annualNv] : [...qtrNv]
            const newCovidNav = viewYear ? [...annualNv] : [...qtrNv]
            const newNetCashFlow = viewYear
                ? [...annualNetCashFlow]
                : [...qtrNetCashFlow];
            const newNcf = viewYear ? [...annualNcf] : [...qtrNcf];

            const emptyCovidNetCashFlow = new Array(newContrib.length);
            const emptyContributions = new Array(newContrib.length);
            const emptyDistributions = new Array(newContrib.length);
            const emptyNav = new Array(newNav.length);
            const emptyCovidNav = new Array(newCovidNav.length)

            const newYears = viewYear ? [...Year] : [...histDates];
            if (viewYear && years[0] === latestYear) {
                // If data overlaps, merge values (commitment)
                covidContributions[0] += annualContrib[annualContrib.length - 1];
                covidDistributions[0] += annualDistrib[annualDistrib.length - 1];
                nav[0] += annualNv[annualNv.length - 1];
                covidNav[0] += annualNv[annualNv.length - 1];
                netCashFlow[0] += newNetCashFlow[newNetCashFlow.length - 1];
            } else if (
                !viewYear &&
                histDates[histDates.length - 1] === years[0]
            ) {
                // If data overlaps, merge values (commitment)
                covidContributions[0] += qtrContrib[qtrContrib.length - 1];
                covidDistributions[0] += qtrDistrib[qtrDistrib.length - 1];
                nav[0] += qtrNv[qtrNv.length - 1];
                covidNav[0] += qtrNv[qtrNv.length - 1];
                netCashFlow[0] += newNetCashFlow[newNetCashFlow.length - 1];
            }

            newContrib.pop();
            newDistrib.pop();
            newNetCashFlow.pop();
            newCovidNav.pop();
            newNav.pop();
            newNcf.pop();

            emptyCovidNetCashFlow.pop();
            emptyContributions.pop();
            emptyDistributions.pop();
            emptyNav.pop();
            emptyCovidNav.pop();

            newYears.pop();
            covidContributions = emptyContributions.concat(covidContributions);
            covidDistributions = emptyDistributions.concat(covidDistributions);
            nav = emptyNav.concat(nav)
            covidNav = emptyCovidNav.concat(covidNav)
            netCashFlow = newNetCashFlow.concat(netCashFlow);
            covidNetCashFlow = newNcf.concat(covidNetCashFlow);
            years = newYears.concat(years);

            historicalContrib = newContrib.concat(emptyFutureContrib);
            historicalDistrib = newDistrib.concat(emptyFutureDistrib);
            historicalNav = newNav.concat(emptyFutureNav)
        }
        const parsedFiscalMonth = fiscalMonth === '' ? 'September' : fiscalMonth;

        const shortMonths = { September: 'Sept.', June: 'Jun.', March: 'Mar.' };

        const addFY = (year) => {
            const month = shortMonths[parsedFiscalMonth];
            return `FY ${month} ${year}`;
        };

        const dataYears = years?.map(addFY);
        const yearLabels = dateType === 'fiscal' ? dataYears : years;

        const label = viewYear
            ? { step: 1, rotation: 'auto', position: 'start' }
            : { step: 4, rotation: 'auto', position: 'start' };

        const title =
            dateType === 'fiscal' && viewYear
                ? { text: 'Fiscal Years' }
                : { text: 'Years' };

        setDataPoints( prevState => ({
            ...prevState,
            currency,
            nav,
            historicalNav,
            yearLabels,
            label,
            title,
            contributions,
            distributions,
            covidContributions,
            covidDistributions,
            historicalContrib,
            historicalDistrib,
            covidNetCashFlow,
            covidNav,
        }))
    };

    useEffect(() => {
        setData();
    }, [graphData, viewYear])

    return {
        dataPoints
    }
}

export default useGraphData