import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthContext } from 'context';
import { Landing, StaticEnvironment } from './Landing';
import ParameterLab from './ParameterLab/ParameterLab';
import PacingAnalysis from './PacingAnalysis/PacingAnalysis';
import ManagePacing from './ManagePacing/ManagePacing';
import AdminUserPage from './AdminUserPage';
import LogoutPage from './LogoutPage';
import NavBarWrapperTechint from 'NavBarWrapperTechint';
import ScenariosPage from 'ScenariosPage';

const Routes = () => {
    const {
        user: {
            spi_client_id: userHasSpiClientId,
            parameter_lab_access: userHasParameterLabAccess,
            is_admin: userIsAdmin,
        },
    } = useContext(AuthContext);

    // we are now guaranteed to have user info by the time this renders
    return (
        <>
            <NavBarWrapperTechint />
            <Switch>
                <Route exact path='/' component={Landing} />
                {userHasSpiClientId && !userHasParameterLabAccess ? (
                    ''
                ) : (
                    <Route path='/parameter-lab' component={ParameterLab} />
                )}
                <Route path="/scenarios" component={ScenariosPage} />
                <Route path='/pacing-analysis' component={PacingAnalysis} />
                <Route path='/manage-pacing' component={ManagePacing} />
                {userIsAdmin && (
                    <Route path='/manage-users' component={AdminUserPage} />
                )}
                <Route exact path='/static-environment' component={StaticEnvironment} />
                <Route exact path='/logout' component={LogoutPage} />
            </Switch>
        </>
    );
};

export default Routes;
