import { useEffect, useState } from 'react';
import { useFormContext } from '@ssgglobal/techintnue';
import { getQuarterAndYearFromDate } from 'utils';

const qtrs = ['Q1', 'Q2', 'Q3', 'Q4'];
const nextQtr = (currentQtr, currentYear) => {
    let qtr = currentQtr;
    let year = parseInt(currentYear);
    let nextIdx = qtrs.findIndex((q) => q === currentQtr) + 1;
    qtr = qtrs[nextIdx]
    if (!qtr) {
        // mod
        qtr = qtrs[nextIdx % 4];
        // up one year
        year += 1;
    }
    return [qtr, year];
}

export const useEndQuarterDates = ({
    reportDate,
    spanYears = 5,
}) => {
    const options = [];
    const initDate = getQuarterAndYearFromDate(reportDate);
    const [qtr, yr] = initDate.split(' ');
    const [nxtQuarter, nextYear] = nextQtr(qtr, yr);
    let year = nextYear;
    let quarterIdx = qtrs.findIndex((q) => q === nxtQuarter);
    for (let count = 0; count < 4 * spanYears; count ++) {
        const quarter = qtrs[quarterIdx];
        options.push({
            label: `${quarter} ${year}`,
        })
        if (quarter === 'Q4') {
            year += 1;                      // move year for next quarter
        } 
        quarterIdx = (quarterIdx + 1) % 4;  // move quarter
    }
    return [options]
}


export const useControllSwitch = ({ name }) => {
    const { watch, register } = useFormContext();
    const field = register(name);
    const watched = watch(name);
    const [value, setValue] = useState(watched);
    const toggleValue = () => setValue(!value);

    useEffect(() => {
        if (watched !== value) {
            field.onChange({ target: { name, value } });
        }
    }, [value]);
    return [
        value,
        toggleValue,
    ];
}
