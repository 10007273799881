import React from 'react'

import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';

import useGraphData from 'PacingAnalysis/hooks/useGraphData';

import { colorCombos } from 'utils';

type Props = {
    viewYear: Boolean,
    viewHistorical: Boolean,
    useCovidScenario: Booloean,
};

const CashFlowDownTurnGraphWrapper = ({
    viewYear,
    viewHistorical,
    useCovidScenario,
    }: Props) => {

    const { 
        dataPoints: {
            currency = '$',
            yearLabels,
            label,
            title,
            covidContributions,
            covidDistributions,
            historicalContrib,
            historicalDistrib,
            covidNetCashFlow
        }
    } = useGraphData(viewYear, viewHistorical, useCovidScenario);

    return (
        <div className='panel'>
            <Chart pannable={false} zoomable={false}>
                <ChartLegend
                    visible
                    position='bottom'
                    orientation='horizontal'
                />
                <ChartAxisDefaults majorGridLines={false} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={yearLabels}
                        labels={label}
                        title={title}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: `Value in ${currency}` }} />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        name='Contributions'
                        type='column'
                        color={colorCombos[1].primary}
                        data={covidContributions}
                        stack={true}
                    />
                    <ChartSeriesItem
                        name='Historical Contributions'
                        type='column'
                        color={colorCombos[1].primary}
                        data={historicalContrib}
                        opacity={0.5}
                        stack={true}
                    />
                    <ChartSeriesItem
                        name='Distributions'
                        type='column'
                        color={colorCombos[0].primary}
                        data={covidDistributions}
                        stack={true}
                    />
                    <ChartSeriesItem
                        name='Historical Distributions'
                        type='column'
                        color={colorCombos[0].primary}
                        data={historicalDistrib}
                        opacity={0.5}
                        stack={true}
                    />
                    <ChartSeriesItem
                        name='Net Cash Flows'
                        type='line'
                        color={colorCombos[2].primary}
                        data={covidNetCashFlow}
                        stack={true}
                    />
                </ChartSeries>
                <ChartTooltip />
            </Chart>
        </div>
    );
}

const CashFlowDownTurnGraph = React.memo(CashFlowDownTurnGraphWrapper);

export default CashFlowDownTurnGraph