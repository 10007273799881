import React, { useContext, useEffect } from 'react';
import { Button, FormInput, FormProvider, Grid, Modal, useForm } from '@ssgglobal/techintnue';
import StrategiesTables from './StrategiesTable';
import { LandingContext, StaticsContext, UnderwritingContext } from 'context';
import { reviseAttributes } from './helper';

const mapValues = {
    'Base': 'values',
    'High': 'valuesHigh',
    'Low': 'valuesLow',
}

const AutoAdjustBetaModal = ({ isOpen, scenario, onClose, openDocumentation }) => {
    const { strategyAgeParameters } = useContext(StaticsContext);
    const { tableData } = useContext(LandingContext);
    const {
        fundSliderValues,
        setFundSliderValues,

    } = useContext(UnderwritingContext);

    const useFormMethods = useForm({
        defaultValues: {
            selectedStrategies: [],
            overrideEdits: false
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    const { handleSubmit, reset } = useFormMethods;

    useEffect(() => {
        // reset form state on modal open
        reset();
    }, [isOpen])

    const onSubmit = ({ selectedStrategies, overrideEdits }) => {
        const strategies = selectedStrategies.map(({ strategy }) => strategy)
        const selectedRows = tableData.filter(({ PacingStrategy }) => strategies.includes(PacingStrategy))
        const selectedIds = selectedRows.map(({ Id }) => Id);

        // calculate new funds values
        const newFundSliderValues = fundSliderValues.map((fund) => {
            // if its not part of selected funds ignore
            if (!selectedIds.includes(fund.id)) {
                return fund;
            }
            const selectedRow = selectedRows.find((row) => row.Id === fund.id);

            // get all scenarios of strategies for selected strategies
            const ageStrategies = strategyAgeParameters
                .filter( 
                    ({ strategy, contribTiming, age, numFunds }) => (
                        strategy === selectedRow.PacingStrategy // select strategies selected
                        && age === selectedRow.age              // get the correct age
                        && contribTiming == 'Base'              // freeze to one option (this value should be the sames for the other options)
                        && numFunds == 5                        // freeze to one option (this value should be the sames for the other options)
                    )
                )
                .map(({ id, contribTiming, numFunds, ...strategy }) => ({ ...strategy }))
            
            if (scenario !== 'All') {
                // update selected scenario
                const scenarioKey = mapValues[scenario];
                const newFundAge = ageStrategies.find((ageStrat) => ageStrat.case === scenario);
                const newFund = reviseAttributes({ fund: newFundAge, prevFund: fund[scenarioKey], overrideEdits });
                return {
                    ...fund, // keep fund attributes
                    // update only selected scenario
                    [scenarioKey]: newFund,
                }
            }

            // update base scenario
            const baseAge = ageStrategies.find(({ case: scenario }) => scenario === 'Base');
            const base = reviseAttributes({ fund: baseAge, prevFund: fund.values, overrideEdits });
            // update high scenario
            const highAge = ageStrategies.find(({ case: scenario }) => scenario === 'High');
            const high = reviseAttributes({ fund: highAge, prevFund: fund.valuesHigh, overrideEdits });
            // update high scenario
            const lowAge = ageStrategies.find(({ case: scenario }) => scenario === 'Low');
            const low = reviseAttributes({ fund: lowAge, prevFund: fund.valuesLow, overrideEdits });

            return {
                ...fund, // keep fund attributes
                values: base,
                valuesHigh: high,
                valuesLow: low,
            }
        })
        setFundSliderValues(newFundSliderValues)

        onClose()
    }

    return (
        <Modal
            title="Auto Adjust Parameters"
            isThemed
            size="xl"
            open={isOpen}
            onClose={onClose}
        >
            <FormProvider useFormMethods={useFormMethods}>
                <Grid container gap="32px">
                    <Grid container item gap="24px">
                        <Grid container item>
                            <StrategiesTables />
                        </Grid>
                        <Grid container item alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormInput
                                    type="checkbox"
                                    name="overrideEdits"
                                    label="Override Existing Edits"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    theme="secondary"
                                    onClick={() => { openDocumentation() }}
                                >
                                    Documentation
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="flex-end" spacing="16px">
                        <Grid item>
                            <Button
                                theme="secondary"
                                onClick={() => { onClose(); }}
                                sx={{ width: '88px' }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                theme="primary"
                                onClick={handleSubmit(onSubmit)}
                                sx={{ width: '88px' }}
                            >
                                Apply Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </FormProvider>
        </Modal>
    )
}

export default AutoAdjustBetaModal
