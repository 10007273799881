import React from 'react'

import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';

import useGraphData from 'PacingAnalysis/hooks/useGraphData';

import { colorCombos } from 'utils';

type Props = {
    viewYear: Boolean,
    viewHistorical: Boolean,
    useCovidScenario: Booloean,
};

const NavDownTurnGraph = ({
    viewYear,
    viewHistorical,
    useCovidScenario,
    isScenario = false,
}: Props) => {

    const { 
        dataPoints: {
            currency = '$',
            nav,
            yearLabels,
            label,
            title,
            covidNav,
            historicalNav
        }
    } = useGraphData(viewYear, viewHistorical, useCovidScenario);

    return (
        <div className='panel'>
            <Chart pannable={false} zoomable={false}>
                <ChartLegend
                    visible
                    position='bottom'
                    orientation='horizontal'
                />
                <ChartAxisDefaults majorGridLines={false} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={yearLabels}
                        labels={label}
                        title={title}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        name='value'
                        title={{ text: `Value in ${currency}` }}
                    />
                </ChartValueAxis>
                <ChartSeries>
                    {
                        isScenario ? (
                            <ChartSeriesItem
                                name='Downturn NAV'
                                type='column'
                                color={colorCombos[0].primary}
                                data={covidNav}
                                axis='value'
                            />
                        ) : [
                                <ChartSeriesItem
                                    name='Historical NAV'
                                    type='column'
                                    color={colorCombos[0].primary}
                                    data={historicalNav}
                                    axis='value'
                                    opacity={0.5}
                                    spacing={0}
                                />,
                                <ChartSeriesItem
                                    name='Base Case NAV'
                                    type='column'
                                    color={colorCombos[0].primary}
                                    data={nav}
                                    axis='value'
                                />,
                                <ChartSeriesItem
                                    name='Downturn NAV'
                                    type='column'
                                    color={colorCombos[1].primary}
                                    data={covidNav}
                                    axis='value'
                                />,
                        ]
                    }
                </ChartSeries>
                <ChartTooltip />
            </Chart>
        </div>
    );
}

export default NavDownTurnGraph