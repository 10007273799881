const AUTO_ADJUST_REVISE_ATTRS = ['irr', 'yld', 'bow', 'fundLife'];

export const reviseAttributes = ({
    fund,
    prevFund,
    overrideEdits = false,
}) => {
    const prevHolder = prevFund;
    prevHolder.userEdited = prevFund.userEdited || [];
    if (overrideEdits) {
        return {
            ...prevHolder,
            irr: fund.irr,
            yld: fund.yld,
            bow: fund.bow,
            fundLife: fund.fundLife,
            // remove the revise attributes from userEdited list
            userEdited: prevHolder.userEdited
                .filter((edited) => !AUTO_ADJUST_REVISE_ATTRS.includes(edited))
        }
    }
    const attrsToUpdate = AUTO_ADJUST_REVISE_ATTRS.filter(
        (attr) => !prevHolder.userEdited.includes(attr)
    );
    const newFund = prevHolder;
    // update newFund
    attrsToUpdate.forEach((attr) => {
        newFund[attr] = fund[attr]
    })
    // remove updated attrs from userEdited list
    newFund.userEdited = prevHolder.userEdited.filter((edited) => !attrsToUpdate.includes(edited))
    return newFund;
}
